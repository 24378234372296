.main{
    display: flex;
    justify-content: space-between;
}

.left-side{
    display: flex;
    flex: 3 1;
    flex-direction: column;
    gap: 2rem;
    padding: 1.5rem 2rem 2rem;
}

.right-side{
    flex:1;
    position:relative;
    background-color:var(--orange);
}

.the-best-sec{
    margin-top: 4rem;
    background-color: #363d42;
    border-radius: 4rem;
    width:fit-content;
    padding:20px 13px;
    text-transform: uppercase;
    color: white;
    position:relative;
    display: flex;
    align-items: center;
    justify-content:flex-start;
    
}

.the-best-sec>span{
    z-index: 2;
}

.the-best-sec>div{
    position:absolute;
    background-color:var(--orange);
    height:80%;
    width: 5.4rem;
    border-radius: 3rem;
    left:8px;
    z-index: 1;

}

.main-text{
    display: flex;
    flex-direction: column;
    gap:1.5rem;
    text-transform: uppercase;
    font-size: 4.5rem;
    font-weight: bold;
    color:white;
    text-overflow: inherit;
}

.main-text>div:nth-of-type(3){
    font-size: 1rem;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;
    width:80%;
    
}

.figures{
    display: flex;
    gap:2rem;
}

.figures>div{
    display: flex;
    flex-direction: column;
}
.figures>div>span:nth-of-type(1){
   color:white;
   font-size: 2rem;   
}
.figures>div>span:nth-of-type(2){
    color:var(--gray); 
    text-transform: uppercase;
 }

 .main-button{
    display: flex;
    gap:1rem;
    font-weight: normal;
 }

 .main-button>:nth-of-type(1){
    color:white;
    background-color: var(--orange);
    width: 8rem;
 }
 .main-button>:nth-of-type(2){
    color:white;
    background-color: transparent;
    border: 2px solid var(--orange);
    width: 8rem;
 }

 .right-side>.btn{
    position: absolute;
    right:3rem;
    top:2rem;
    color:black;
 }

 .heart-rate{
    display: flex;
    flex-direction: column;
    gap:1rem;
    background-color:var(--darkGrey);
    width: fit-content;
    padding:1rem;
    align-items: flex-start;
    border-radius: 5px;
    position: absolute;
    right:4rem;
    top:7rem;

 }
 .heart-rate img{
    width:2rem;
 }

 .heart-rate>:nth-child(2){
    color:var(--gray);
 }
 .heart-rate>:nth-child(3){
    color:white;
    font-size: 1.5rem;
 }

 .hero-image{
    position:absolute;
    top:10rem;
    right:8rem;
    width:23rem;
 }

 .hero-image-back{
    position: absolute;
    top:4rem;
    right:20rem;
    z-index: -1;
    width:15rem;
 }

 .calories{
    display: flex;
    gap:2rem;
    background-color:var(--caloryCard);
    padding:1rem;
    border-radius: 5px;
    width:13rem;
    position:absolute;
    top:32rem;
    right:28rem;
 }

 .calories>img{
    width:3rem;
 }

 .calories>div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
 }
 .calories>div>:nth-child(1){
    color:var(--gray);
 }

 .calories>div>:nth-child(2){
    color:white;
    font-size: 1.5rem;
 }


 .main-blur{
   width: 22rem;
   height: 30rem;
   left:0;
 }

@media screen and (max-width: 768px){

   .main{
      flex-direction: column;
   }
   .main-blur{
      width:14rem;
   }

   .the-best-sec{
      margin-top:0;
      font-size: small;
      align-items:center;
      transform: scale(0.8);

   }
   .main-text{
      font-size: xx-large;
      justify-content: center;
      align-items:center;
   }
   .main-text>div:nth-of-type(3){
      font-size: small;
      font-weight: 200;
      letter-spacing: 1px;
      align-items: center;
   }

   .figures>div>span:nth-of-type(1){
      font-size: large;
   }
   .figures>div>span:nth-of-type(2){
      font-size: small;
   }

   .main-button{
      justify-content: center;
   }

   .right-side{
      position: relative;
      background: none;
   }

   .heart-rate{
      left: 1rem;
      top:2rem;
   }

   .calories{
      position: relative;
      top:5rem;
      left:2rem;
   }
   .calories>img{
      width:2rem;
   }

   .calories>div>:nth-child(2){
      color:white;
      font-size: 1rem;
   }
   .hero-image{
      position: relative;
      width: 15rem;
      left:7rem;
      top:4rem;
      align-self: center;
   }
   .hero-image-back{
      width:15rem;
      left:2rem;
      top:0rem;
   }


}