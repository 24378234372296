.program{
    display: flex;
    flex-direction: column;
    gap:2rem;
    padding:0 2rem;
}

.program-header{
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    color:white;
    text-transform: uppercase;
    font-style: italic;

}

.programs-catagory{
    display: flex;
    gap:1rem;
}

.catagory{
    display: flex;
    flex-direction: column;
    background-color:gray;
    gap: 1rem;
    padding: 2rem;
    justify-content: space-between;
    color:white;
}

.catagory>:nth-child(1){
    width: 2rem;
    height: 2rem;
    fill:white;
}
.catagory>:nth-child(2){
    font-weight: bold;
    font-size: 1rem;

}
.catagory>:nth-child(3){
    font-size: 0.9rem;
    line-height: 25px;

}

.join-now{
    display: flex;
    align-items: center;
    gap:2rem;

}
.join-now img{
    width: 1rem;
}

.catagory:hover{
    background: var(--planCard);
    cursor: pointer;;
}

@media screen and (max-width:768px){
    .program-header{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: x-large;
        gap:1rem;
        margin-top: 2rem;
    }

    .programs-catagory{
        flex-direction: column;
    }
}



