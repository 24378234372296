.footer-container{
    position: relative;
}

.footer-container>hr{
    border:1px solid var(--lightgray);
}

.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap:4rem;
    justify-content: center;
    height: 20rem;
    align-items: center;
    cursor: pointer;
}

.social-links{
    display: flex;
    gap:4rem;
}

.social-links>a>img{
    width: 2rem;
    height: 2rem;
}

.blur-f-1{
    bottom: 0;
    right: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: red;
}
.blur-f-2{
    bottom: 0;
    left: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: rgba(255,155,0);
}

@media screen and (max-width: 768px){
    .logo-footer{
        width:12rem;
    }
}