.join{
    display: flex;
    padding: 0 2rem;
    gap:10rem;
}

.left-j{
    font-size: 3rem;
    font-weight: bold;
    color:white;
    position: relative;
}

.left-j>hr{
    position: absolute;
    width:10.5rem;
    border:2px solid var(--orange);
    border-radius: 20%;
    top:-2rem;
}

.right-j{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}


.email-container{
    display: flex;
    background-color: gray;
    padding:1rem 2rem;
    gap:3rem;
}

.email-container>input{
    background-color: transparent;
    border: none;
    outline: none;
    color:var(--lightgray)
}

::placeholder{
    color:var(--lightgray);
}

.btn-j{
    background-color: var(--orange);
    color:white;
}

@media screen and (max-width: 768px){

    .join{
        flex-direction: column;
        gap: 1rem;
    }

    .left-j{
        flex-direction: column;
        font-size: xx-large;
    }
    .right-j{
        padding: 2rem;
        
    }


}